define("discourse/plugins/discourse-whos-online/discourse/templates/components/whos-online-avatar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{avatar
    this.user
    avatarTemplatePath="avatar_template"
    title=this.user.username
    imageSize="small"
  }}
  */
  {
    "id": "1kVu9FmO",
    "block": "[[[1,[28,[35,0],[[30,0,[\"user\"]]],[[\"avatarTemplatePath\",\"title\",\"imageSize\"],[\"avatar_template\",[30,0,[\"user\",\"username\"]],\"small\"]]]]],[],false,[\"avatar\"]]",
    "moduleName": "discourse/plugins/discourse-whos-online/discourse/templates/components/whos-online-avatar.hbs",
    "isStrictMode": false
  });
});